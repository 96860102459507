@media (min-width: 800px) {
  main {
    max-width: 80%;
  }
}
@media (min-width: 1100px) {
  main {
    max-width: 100%;
  }
}
@media (max-width: 799px) {
  main {
    max-width: 100%;
  }
}
